<template>
  <main class="mt-5">
    <div class="container-logo">
      <!-- <div class="container-logo-flex">
        <img :src="'/images/logo2.png'" class="company-logo"/>
      </div> -->
      <div class="container-description">
        <p class="text-uppercase-ex">
          REGISTRO EX-UNIVERSITARIO
        </p>
      </div>
    </div>
    <FormExuniversitarioPartials></FormExuniversitarioPartials>
  </main>
</template>

<script>
import FormExuniversitarioPartials from "./partials/FormExuniversitario.partials.vue";
export default {
  components: {
    FormExuniversitarioPartials,
  },
};
</script>

<style land="scss">
@import "../styles/comunityexuniversitaria.scss";
</style>
